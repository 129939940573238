<template lang="pug">
  v-container(fill-height)
    v-layout(align-center)
      v-flex(text-center)
        h1.display-2.primary--text Whoops, 404
        p The page you were looking for does not exist
        v-btn(to="/", outlined color="primary")
          | Вернуться на главную
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
